@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
* {
  font-family: "Nunito Sans", sans-serif;
}

:root {
  --yellow: #fee101;
  --white: #ffffff;
}

.banner {
  min-height: calc(100vh);
}
#banner {
  background-color: #000;
  background-image: url(/public/img/bg-banner.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.main-heading {
  font-size: 40px;
  font-weight: 700;
  line-height: 54.56px;
  text-align: left;
}
.sub-heading-bold {
  font-size: 38.58px;
  font-weight: 800;
  line-height: 42.19px;
  text-align: center;
}
.sub-heading {
  font-size: 35px;
  font-weight: 700;
  line-height: 35px;
  text-align: left;
}
.sub-heading-small {
  font-size: 28.93px;
  font-weight: 600;
  line-height: 34px;
  text-align: center;  
}
.form-heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 18.58px;
  margin-top: 20px;
  text-align: left;
  display: block;
}
.form-heading-light {
  font-size: 20.9px;
  font-weight: 700;
  line-height: 18.58px;
  text-align: left;
}
.p-small{
font-size: 12px;
font-weight: 400;
line-height: 24px;
text-align: left;
color: #FFFFFF;
opacity: 0.80;
}
.opt-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 18.58px;
  text-align: left;
}
.font-yellow {
  color: var(--yellow);
}
.container {
  max-width: 1064px !important;
}
span.input-group-text {
  border: none;
  background: transparent;
  border-bottom: solid 1px #fff;
  border-radius: 0;
}
input.form-control {
  border: none;
  background: transparent;
  border-bottom: solid 1px #fff;
  border-radius: 0;
  color: #ffffff;
  padding: 0;
  height: 40px;
  font-size: 18px;
}
input.form-control:hover,
input.form-control:focus,
input.form-control:visited {
  background: transparent !important;
  color: #ffffff;
  box-shadow: none;
}
input.form-control::placeholder {
  color: #ffffff34;
  font-size: 18px;
  font-weight: 400;
  line-height: 27.28px;
  text-align: left;
}
button.btn-style {
  background: #fff;
  border: none;
  color: #000;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.28px;
  text-align: center;
  padding: 10px 40px;
  width: 364px;
  cursor: pointer;
}
fieldset {
  padding: 0;
}
button.btn-style-two.btn {
  background: #2d2d45;
  border: 0.58px solid #4d4d4d;
  padding: 10px 18px;
  display: flex;
  align-items: center;
  gap: 9px;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  color: #fff;
  width: 170px;
  border-radius: 6px;
  cursor: pointer;
}
.box-w {
  width: 364px;
}
.g-20 {
  gap: 20px;
}
.g-10 {
  gap: 10px;
}
.otp-send input {
  width: 70px !important;
  height: 70px !important;
  background: #2d2d45;
  border-radius: 12px;
  box-shadow: none !important;
  color: #fff;
  font-size: 30px;
}
.otp-send div {
  gap: 12px;
}
.exp {
  margin-right: -66px;
}
a.hover-none {
  text-decoration: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  filter: brightness(0) invert(0.8);
  color: white;
  width: 1.5em; /* Increase the size of the icon */
  height: 1.5em; /* Increase the size of the icon */
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
  display: none; /* Hide the inner buttons */
}

input[type="date"] {
  color: white;
  background-color: transparent; /* Example: dark background for the input */
  border-bottom: 1px solid #ced4da;
  border-radius: 0rem;
  padding: 0.5rem;
  font-size: 1rem;
  padding-left: 0;
}
select#exampleForm\.SelectCustom option {
  color: #000;
}
select#exampleForm\.SelectCustom {
  filter: brightness(0) invert(0.8);
  background-color: transparent;
  color: #fff;
  border: none;
  border-bottom: solid 1px;
  border-radius: 0;
  padding-left: 0;
  box-shadow: none;
  background-size: 20px;
}
.bo-bottom {
  border-bottom: solid 1px #fff;
  padding-bottom: 8px;
}
select#state {
  padding-left: 0;
  font-size: 16px;
}
.bo-bottom img {
  margin-left: -34px !important;
}
#state option {
  color: #000;
}
span.fo-small.text-white {
  font-size: 14px;
  color: red;
  display: block;
  margin: 7px 0 -2px 0;
}
small{
  font-size: 12px;
  opacity: 0.80;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;

}
.down .container{
  max-width: 1180px !important;
}
.fo-500{
  font-weight: 500 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
img.bonus-img1,img.mob-2 {
  width: 250px;
  object-fit: contain;
}
.mobile-sec {
  margin-top: 204px !important;
}

img.bonus-img {
  margin-top: 40%;
}
.inp-img img {
  margin-bottom: 12px;
}


@media (max-height: 700px) {
.dow-logo{
  position: relative !important;
  margin-bottom: 100px;
}
}
@media (max-height: 850px) {
  .mobile-sec {
    margin-top: 100px !important;
  }
  .dow-logo{
    position: relative !important;
    margin-bottom: 100px;
  }
  }



@media (min-width: 767px) {
  .pos-ab {
    position: absolute;
    top: 40px;
}

  img.bonus-img1 {
    padding-bottom: 15%;
  }
  .mob-2{
    padding-top: 15%;
  }

  .d-flex.align-items-center {
    position: absolute;
    top: 40px;
  }
  .last {
    position: absolute;
    bottom: 100px;
  }

}

@media (max-width: 1220px) {
  .exp {
    margin-right: 90px;
  }
  
  .otp-send input {
    width: 60px !important;
    height: 60px !important;
    background: #2d2d45;
    border-radius: 12px;
    box-shadow: none !important;
    color: #fff;
    font-size: 30px;
  }
  .otp-send span {
    display: none;
}
 
  
}
@media (max-width: 1024px) {
  .otp-send input {
    width: 50px !important;
    height: 50px !important;
  }
  p.exp {
    margin-right: 0px;
    text-align: left !important;
  }
  .last {
    position: relative;
    bottom: 0px;
    margin-top: 20px;
}
.ali-self{
  align-self: flex-start !important;
}
img.bonus-img {
  margin-top: 40%;
}
    img.bonus-img1,img.mob-2 {
        width: 50%;
    }
}

@media (max-width:768px) {
  img.bonus-img1, img.mob-2 {
    width: 46%;
    object-fit: contain;
    margin: 0 !important;
  }

  .mobile-sec {
    margin-top: 50px !important;
}
  img.bonus-img1 {
   margin-bottom: 20px;
  }
  .sub-heading {
    font-size: 25px;
}
.main-heading {
  font-size: 25px;
}
  .full-w {
    width: 100% !important;
    text-align: center;
}
img.bonus-img {
  width: 80%;
  height: auto;
  margin-top: 20px;
}
.form-heading {
  font-size: 16px;
}
input.form-control::placeholder {
  font-size: 16px;
}
.sub-heading-bold {
  font-size: 32.58px;
  line-height: 22.19px;
}
.sub-heading-small {
  font-size: 20.93px;
  line-height: 34.19px;
}
.otp-send div {
  gap: 12px;
  justify-content: space-between !important;
  width: 100%;
} 
.otp-send input {
  width: 60px !important;
  height: 60px !important;
}
}

@media (max-width: 500px) {
 
  img.bonus-img {
    margin-top: 0px;
  }
  .bonus-img {
    margin: auto;
    width: 100% !important;
    height: auto !important;
  }
  .full-w {
    width: 100% !important;
    margin: auto;
  }
  img.logo {
    width: 50px;
  }
  .otp-send span {
    display: none;
  }
  .otp-send div {
    gap: 10px;
    justify-content: space-between;
  }
 
  button.btn-style {
    padding: 10px 50px;
    width: 100% !important;
    text-align: center;
}
}
@media (max-width: 420px) {
  .otp-send input {
    width: 45px !important;
    height: 45px !important;
  }
}

@media (min-width: 1940px) {
  .box-w {
    width: 364px;
  }
  .ali-self{
    align-self: flex-start !important;
  }
img.bonus-img {
    margin-top: 40%;
}
.last {
  position: relative;
  margin-top: 40%;
  bottom: 100px;
}
}
  