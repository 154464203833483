@media (max-width: 424px) {
    .about-page-padding {
      padding-left: 650px;
      padding-right: 650px;
    }
}
@media (min-width: 425px) and (max-width: 511px) {
    .about-page-padding {
      padding-left: 600px;
      padding-right: 600px;
    }
}
@media (min-width: 512px) and (max-width: 587px) {
    .about-page-padding {
      padding-left: 550px;
      padding-right: 550px;
    }
}
@media (min-width: 588px) and (max-width: 766px) {
    .about-page-padding {
      padding-left: 500px;
      padding-right: 500px;
    }
}
@media (min-width: 767px) and (max-width: 824px) {
    .about-page-padding {
      padding-left: 440px;
      padding-right: 440px;
    }
}
@media (min-width: 825px) and (max-width: 999px) {
    .about-page-padding {
      padding-left: 350px;
      padding-right: 350px;
    }
}

@media (min-width: 1000px) and (max-width: 1074px) {
    .about-page-padding {
      padding-left: 240px;
      padding-right: 240px;
    }
}

@media (min-width: 1075px) and (max-width: 1274px) {
    .about-page-padding {
      padding-left: 200px;
      padding-right: 200px;
    }
}

@media (min-width: 1275px) and (max-width: 1354px) {
    .about-page-padding {
      padding-left: 70px;
      padding-right: 70px;
    }
}

@media (min-width: 1355px) {
    .about-page-padding {
      padding-left: 20px;
      padding-right: 20px;
    }
}
