@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  @apply leading-[normal] m-0;
}
*,
::before,
::after {
  border-width: 0;
}
