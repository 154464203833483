
.image-size {
    height: 50px;
    width: 50px;
    object-fit: contain;
}

@media (max-width: 500px){
    .card-font {
        font-size: 12px;
    }
    .image-size {
        height: 50px;
        width: 50px;
    }
}

@media (min-width: 501px) and (max-width: 768px){
    .image-size{
        height: 50px;
        width: 50px;
    }
    .card-font{
        font-size: 12px;
    }
}

@media (min-width: 769px){
    .image-size{
        height: 50px;
        width: 50px;
    }
    .card-font{
        font-size: 20.04px;
    }
}