/* Add a general style for your app */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}
body::-webkit-scrollbar{
  display: none;

}

/* Add responsive styles for different screen sizes */
@media only screen and (max-width: 600px) {

    /* Styles for screens smaller than 600px wide */
    body {
        font-size: 14px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {

    /* Styles for screens between 601px and 1024px wide */
    body {
        font-size: 16px;
    }
}

@media only screen and (min-width: 1025px) {

    /* Styles for screens larger than 1024px wide */
    body {
        font-size: 18px;
    }
}
a{
  text-decoration: none;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width : 1220px){
    .gridient-bg{
      width: 96%;
      margin: auto;
    }
  }

  @media (max-width : 805px){
  .swiper-slide-group{
    width: calc(100% - 4px) !important;
  }
}
  
  .swiper-button-prev {
    z-index: 10;
    left: 10px;
    right: auto;
}

.swiper-button-next {
        z-index: 10;
        right: 10px;
        left: auto;
    }

  .swiper-button-next, .swiper-button-prev {
    width: 49px;
  }

  .swiper-button-prev:after, .swiper-button-next:after
  { 
    width: 100px;
    content: "next";
    color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    
  }
  .swiper-button-next:after{
    background-image: url(/public/right-slide.svg);
    /* background-size: 100%; */
    /* background-size: contain; */
    /* margin-right: 101px; */
  }
    .swiper-button-prev:after{
        background-image: url(/public/swipercircule.svg);
        z-index: 99;
    }

    .corner-border {
        position: absolute;
        width: 60%;
        height: 50%;
        left: 8%;
        top: -2%;
        border-left: 6.17px solid rgba(254, 225, 1, 1);
        border-top: 6.17px solid rgba(254, 225, 1, 1);
        border-top-left-radius: 40px;
        margin-top: 30px;
        margin-bottom: 30px;
        
      }

      .bottom-corner {
        position: absolute;
        width: 60%;
        height: 50%;
        right: 8%;
        bottom: -2%;
        border-right: 6.17px solid rgba(254, 225, 1, 1);
        border-bottom: 6.17px solid rgba(254, 225, 1, 1);
        border-bottom-right-radius: 40px;
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .swiper-css-mode > .swiper-wrapper {
        overflow-y: hidden;
      }

      /* .bottom-corner::after {
        content: ' ';
        position: absolute;
        background: transparent;
        top: 12%;
        left: -38%;
        z-index: 1;
        border-style: solid;
        border-width: 0.75rem 0.43rem 0.6rem 0rem;
        border-color: transparent rgba(254, 225, 1, 1) transparent transparent;
    } */
      /* vertical line */
      /* .corner-border::after {
        content:'';
        position: absolute;
        background-color: pink;
        width: 3px;
        height: 60%;
        top: 0;
        left:-15px;
        border-top-left-radius: 40px; 
      } */
      
      /* horizontal line */
      /* .corner-border::before{
        content:'';
        position: absolute;
        background-color: transparent;
        width: 80%;
        height: 80%;
        top: 0;
        left:-14px;
        border-left: 2px solid #85C1E9;
        border-top: 2px solid #85C1E9;
       border-top-left-radius: 40px;
      } */
      
      /* vertical line */
      /* .bottom-corner::after {
        content:'';
        position: absolute;
        background-color: #85C1E9;
        width: 3px;
        height: 50%;
        bottom: 10;
        right:45px;
        border-bottom-right-radius: 40px; 
      } */
      /* horizontal line */
      /* .bottom-corner::before {
        content:'';
        position: absolute;
        background-color: #85C1E9;
        width: 20%;
        height: 3px;
        bottom: -210;
        right:45px;
        border-bottom-right-radius: 40px; 
      } */
  
      ::-webkit-scrollbar-thumb {
        background: #ECC547 var(--tw-gradient-to-position); 
        border-radius: 2px;
      }
      
      ::-webkit-scrollbar {
        width: 5px;
      }
      
      ::-webkit-scrollbar-track {
        background: #fff; 
        border-radius: 2px;
      }

  .gL .swiper-slide-active{
    background: linear-gradient(to bottom, rgba(18, 71, 149, 1), rgba(14, 19, 61, 1));
    border: 2px solid rgba(255, 255, 255, 1);
    border-radius: 22.67px;
  }
  .gL .swiper-slide-next, .gL .swiper-slide-prev{
    /* background:rgba(45, 45, 69, 0.5); */
    opacity: 0.5 !important;
    background: linear-gradient(90deg, rgba(7, 13, 26, 0.98) 51.71%, rgba(14, 19, 61, 0.98) 98.99%) !important;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 22.67px;
  }

  .gL .swiper-slide-group.swiper-slide-active {
  width: 600px !important; 
}
.swiper-button-prev,.swiper-button-next{
  z-index: 99 !important;
}

/* .swiper-slide-group.swiper-slide-prev,
.swiper-slide-group.swiper-slide-next {
  width: 300px !important; 
  transition: width 0.3s ease; 
} */

/* Ensure slides have the same height */
.gL .swiper-slide-group {
  height: 350px !important;
}

  /* .swiper-slide-active .lines{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("/public/mask.png");
    background-repeat: no-repeat;
    background-size: cover;
  } */
/* Add more media queries as needed for specific components or layouts */



.bg-container {
  background-image: url('/public/img/hero-img.jpg'); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  background-color: #050b12;
}
.bg-about-container {
  background-image: url('/public/img/about-bg.png'); /* Replace with your background image URL */
  background-size: cover;
  background-position: center;
  background-color: #040B18;
}

.mont{
  font-family: "Montserrat", sans-serif !important;
}
.mont-i {
    font-style: italic !important;
}
.yellow{
  color: #C99E19;
}
.off-white{
  color: #D6D7DA;
}
button.download-button {
  background: linear-gradient(92.23deg, #CCA017 0%, #38417D 67.88%, #171B34 117.04%);
  color: #fff;
  width: 220px;
  height: 56px;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  letter-spacing: 1px;
  border-radius: 50px;
  text-transform: uppercase;
  cursor: pointer;
}
.gridient-bg {
  background: linear-gradient(92.23deg, #CCA017 0%, #38417D 67.88%, #171B34 117.04%);
  border-radius: 20px;
}
button.download-button:hover {
  background: linear-gradient(92.23deg, #CCA017 15%, #38417D 63.88%, #171B34 138.04%);
}
.border-right-cs{
position: relative;
}
.border-right-cs:after {
  content: "";
  position: absolute;
  right: -39px;
  height: 100%;
  top: 0;
  border-right: 1px solid #FFFFFF33;
}
.nft-container {
  background: #040B13;
}
.how-border {
  background: linear-gradient(90deg, rgba(7, 13, 26, 0.9) 51.71%, rgba(14, 19, 61, 0.9) 98.99%);
  border: 2px solid #FFFFFF1A;
  padding: 39px 24px;
  border-radius: 16px;
  width: 575px;
  height: 209px;
}
.img-box {
  position: relative;
}
.img-box:after {
  content: '';
  position: absolute;
  left: -7px;
  top: -7px;
  width: 80px;
  height: 80px;
  background-color: #3c2f0c;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 20px #CCA017B2);
}
img.logo-shadow {
  display: block;
 position: relative;
 z-index: 99;
}
.add-vector:before {
  content: "";
  background-image: url(/public/img/arrow-vector.png);
  width: 300px;
  height: 102px;
  position: absolute;
  left: -235px;
  background-repeat: no-repeat;
  bottom: 32px;
}
.add-vector{
  position: relative;
}
.earn-bg {
  background: linear-gradient(90deg, rgba(7, 13, 26, 0.9) 51.71%, rgba(14, 19, 61, 0.9) 98.99%);
  border: 2px solid #FFFFFF1A;
  padding: 40px 30px;
  border-radius: 20px;
}
.watch-sec {
  width: 380px !important;
  height: 550px;
  object-fit: cover !important;
  padding: 20px;
  border-radius: 30px;
  background: linear-gradient(90deg, rgba(7, 13, 26, 0.9) 51.71%, rgba(14, 19, 61, 0.9) 98.99%);
  border: 2px solid #FFFFFF1A;
}
.watch-sec img{
  height: 100% !important;
  object-position: top;
  object-fit: cover;
  border-radius: 20px;
}

@media(max-width:991px){
  .how-border {
    width: auto !important;
    height: auto !important;
}
}


@media (max-width : 550px){
 
  .gL .swiper-slide-group.swiper-slide-active{
    width: 390px !important;
    height: auto !important;
  }
  .gL .swiper-slide-group {
    height: auto !important;
}
  .ct-how-img {
    width: 33% !important;
    object-fit: contain;
}
.how-border {
  padding: 20px 10px;
  border-radius: 16px;
}
.how-border h2{
  line-height: 30px !important;
}
.add-vector:before{
 background: none !important;
}
}
