/* @media (max-width: 1405px){
  .responsive-border{
    max-width: 100%;
  }
}

@media (min-width: 1406px) and (max-width: 1512px) {
    .responsive-border{
      max-width: 106%;
    }
}

@media (min-width: 1513px) {
  .responsive-border{
    max-width: 100%;
  }
}
 */

 .responsive-border{
  max-width: 106%;
  width: 106%;
 }
